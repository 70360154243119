import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://e8020dc23dd323cda54ab15ceda73417@o520718.ingest.us.sentry.io/4507567414968320",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});